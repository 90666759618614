:root {
  --theme-color: #2d3838;
  --white-color: #ffffff;
  --disable-btn-color: #2d38384d;
  --border-color: #909696;
  --gray-color: #2d38384d;
  --black-color: #000;
  --light-theme-color: #f1e7d5;
  --theme-font-family: "Open Sans", sans-serif;
}
body {
  font-family: "Open Sans", sans-serif !important;
}
.auth-section {
  height: 100vh;
  background-image: url("../public/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.auth-cardview {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 480px;
  width: 100%;
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5) !important;
  border-radius: 10px !important;
}
.auth-cardheader {
  background-color: var(--theme-color);
  padding: 10px;
  width: 100%;
}
.auth-cardheader img {
  height: 40px;
}
.common-btn {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
  width: 155px !important;
  text-transform: none !important;
  white-space: nowrap;
  box-shadow: unset !important;
  font-weight: bold !important;
  font-family: var(--theme-font-family) !important;
}
.common-btn:disabled {
  background-color: var(--disable-btn-color) !important;
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
.common-link {
  color: var(--theme-color) !important;
  font-weight: 700 !important;
  text-decoration-color: var(--theme-color) !important;
}
.common-input > fieldset:focus {
  border-color: inherit !important;
  border-width: inherit !important;
}
.dashboardtblScroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  display: block !important;
  min-height: calc(100vh - 210px);
}
.sidebar-drawer-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  border-top: 1px solid var(--border-color);
  border-bottom: none;
  padding: 12px;
  margin-top: auto;
}
.sidebar-drawer-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: var(--theme-color) !important;
  border: none;
  color: var(--white-color) !important;
  width: 400px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 15px 26px !important;
}
.no-record-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color) !important;
  height: 350px;
}
.common-btn.confirm-common-btn {
  width: 100px !important;
}
.common-btn.common-btn-gray {
  background: var(--gray-color) !important;
}
.common-btn.opacity-05 {
  opacity: 0.5;
}
.template-cardheader {
  background-color: var(--theme-color);
  /* height: 16px; */
  padding-left: 0px !important;
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid var(--border-color);
}
.template-cardheader .MuiCardHeader-title {
  display: flex;
  align-items: center;
}
.template-cardheader .MuiIconButton-root {
  color: var(--white-color);
}
.template-cardheader .MuiTypography-root {
  margin-left: 5px;
  color: var(--white-color);
  font-size: 20px !important;
  font-weight: bold !important;
}
.template-card-content-scroll {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  display: block !important;
  min-height: calc(100vh - 270px);
}
.template-sub-cardheader {
  background-color: var(--light-theme-color);
  border-radius: 14px 14px 0px 0px;
}
.template-sub-cardheader .MuiCardHeader-title {
  font-size: 16px !important;
  font-weight: bold !important;
}
.template-cardfooter {
  padding: 15px !important;
}
.template-cardfooter .MuiTypography-root {
  font-weight: bold !important;
}
.template-cardfooter .common-btn.common-btn.common-btn-gray {
  width: 170px !important;
  color: var(--black-color) !important;
}
.no-border {
  border: none !important;
}
.dashed-border {
  border-radius: 15px;
  border: 1px dashed var(--border-color) !important;
}
.dashed-border img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mb-20 {
  margin-bottom: 20px;
}
.full-width {
  width: 100% !important;
}
.flag-chip {
  padding: 22px 5px !important;
  border-radius: 5px !important;
}
.flag-chip.active {
  border: 1px solid var(--theme-color) !important;
  background-color: var(--light-theme-color) !important;
}
.flag-chip.Mui-disabled {
  opacity: 0.7 !important;
}
.flag-chip .MuiChip-label {
  font-family: var(--theme-font-family);
  font-size: 16px;
}
.flag-chip.active .MuiChip-label {
  font-weight: bold;
}
.preview-dialog .MuiDialogTitle-root {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.preview-dialog .MuiTabs-root button {
  width: 250px;
  border-radius: 10px;
  background: var(--light-theme-color);
  color: var(--theme-color);
  margin-right: 5px;
  font-size: 16px;
  text-transform: none;
}
.preview-dialog .MuiTabs-root button.Mui-selected {
  background: var(--theme-color);
  color: var(--white-color);
  text-decoration: none !important;
}
.preview-dialog .preview-tab span.MuiTabs-indicator {
  display: none;
}
.preview-dialog .imageview-box {
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
}
.preview-dialog .imageview-box > img {
  width: 100%;
}
.preview-dialog .imageview-box .contentview-box {
  position: absolute;
  display: flex;
  top: 60px;
  background: var(--white-color);
  border: 2px solid var(--border-color);
  border-radius: 18px;
  width: calc(100% - 42px);
  left: 20px;
  right: 20px;
}
.preview-dialog .imageview-box .contentview-box .image-view {
  width: 100px;
  padding: 10px;
}
.preview-dialog .imageview-box .contentview-box .image-view > img {
  width: 100%;
}
.preview-dialog .imageview-box .contentview-box .details-view {
  display: flex;
  flex-flow: column;
  width: calc(100% - 100px);
  margin-top: 10px;
}
.preview-dialog .imageview-box .contentview-box .details-view > p {
  padding-bottom: 5px;
  margin: 0;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.preview-dialog .imageview-box .contentview-box .details-view > p ::after {
  content: "..."; /* Content to display as ellipsis */
  position: absolute;
  bottom: 0;
  right: 0;
  background: white; /* Match the background color of your container */
  padding: 0 5px; /* Add padding to make the ellipsis more visible */
}
.preview-dialog
  .imageview-box
  .contentview-box
  .details-view
  .twoline-epllipsis {
  height: 27px;
  margin-bottom: 10px;
}
.preview-dialog .imageview-box.expand-view .contentview-box {
  flex-flow: column;
}
.preview-dialog .imageview-box.expand-view .contentview-box .details-view {
  width: calc(100% - 20px);
  margin-top: 0;
  padding-left: 10px;
}
.preview-dialog .imageview-box.expand-view .contentview-box .image-view {
  margin: 0 auto;
}
.oneditload-datetime-picker .MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}
.oneditload-datetime-picker .MuiInputLabel-root.Mui-error.Mui-focused {
  color: var(--theme-color) !important;
}
.oneditload-datetime-picker .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.oneditload-datetime-picker
  .Mui-error.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-color) !important;
}
.plr-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-color) !important;
}
.MuiFormControl-root .Mui-disabled {
  color: var(--black-color) !important;
  -webkit-text-fill-color: var(--black-color) !important;
  background-color: hsl(0, 0%, 95%) !important;
  border-color: hsl(0, 0%, 90%) !important;
  cursor: not-allowed !important;
}
.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color);
}
.MuiFormControl-root label.Mui-disabled {
  background-color: unset !important;
}
.Mui-disabled .MuiOutlinedInput-notchedOutline {
  color: var(--black-color) !important;
}
.theme-scroll-bar::-webkit-scrollbar {
  width: 4px;
}
.theme-scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.theme-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  outline: 1px solid var(--border-color);
}
.sidebar-drawer {
  width: 100%;
}
.sidebar-drawer .MuiPaper-root {
  box-shadow: unset !important;
}
.MuiTypography-root {
  font-family: var(--theme-font-family) !important;
}
.MuiFormControl-root .MuiFormLabel-root {
  font-family: var(--theme-font-family) !important;
}
.MuiFormControl-root .MuiInputBase-root {
  font-family: var(--theme-font-family) !important;
}
.MuiFormHelperText-root {
  font-family: var(--theme-font-family) !important;
}
.MuiOutlinedInput-root {
  font-family: var(--theme-font-family) !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-selectLabel,
.MuiInputBase-root,
.MuiTablePagination-displayedRows {
  font-family: var(--theme-font-family) !important;
}
.MuiTooltip-tooltip {
  font-family: var(--theme-font-family) !important;
}
.MuiFormLabel-root.Mui-focused {
  color: var(--theme-color) !important;
}
.MuiButtonBase-root.Mui-selected {
  background-color: var(--theme-color) !important;
}
.MuiDateCalendar-root .MuiPickersCalendarHeader-labelContainer,
.MuiButtonBase-root {
  font-family: var(--theme-font-family) !important;
}
.MuiPickersLayout-actionBar .MuiButtonBase-root {
  background-color: var(--theme-font-family) !important;
  background-color: var(--theme-color) !important;
  color: var(--white-color) !important;
}

.MuiPaper-root .MuiMultiSectionDigitalClock-root::-webkit-scrollbar {
  width: 4px;
}
.MuiPaper-root .MuiMultiSectionDigitalClock-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.MuiPaper-root .MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  outline: 1px solid var(--border-color);
}
.MuiMenu-list .MuiTablePagination-menuItem.Mui-selected {
  color: var(--white-color);
}
.undermaintenance-container {
  background-color: var(--light-theme-color) !important;
  position: fixed;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}
.toast-msg-appbar {
  background-color: var(--light-theme-color) !important;
  color: var(--theme-color) !important;
}
.playerDiv {
  width: 100%;
  position: relative;
  background-color: var(--theme-color);
}

.playerDiv video {
  width: 100%;
  position: absolute;
}

.controls__div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
}
.MuiDialog-container {
  height: auto !important;
}
