.controls__div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
}

.controls__icons {
  color: #fff;
  font-size: 50;
  transform: scale(0.9);
}

.controls__icons:hover {
  color: var(--light-theme-color);
  transform: scale(1);
}

.volume__slider {
  width: 100;
  margin-top: -50px;
  margin-left: 185px;
  color: var(--light-theme-color) !important;
}

.bottom__icons {
  color: white;
}

.bottom__icons.fullscreen {
  color: white;
}

.bottom__icons:hover {
  color: var(--light-theme-color);
}

.light-theme-color {
  color: var(--light-theme-color);
}

.theme-color {
  color: var(--theme-color);
}

.white-color {
  color: var(--white-color);
}

.overlay-video-spinner {
  backdrop-filter: blur(0.2em);
  background: var(--light-theme-color);
  z-index: 999;
}

.controls__div .loader {
  height: 100%;
}
